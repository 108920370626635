<template>
  <div>
    <pre>{{ formattedJson }}</pre>
  </div>
</template>

<script>
import Mock from "mockjs";

export default {
  data() {
    return {
      jsonData: [], // 存储生成的订单数据
      storelist: [], // 存储店铺名称列表
      cardList: [
        { cardname: "日卡", amount: 19, days: 1, weight: 10 },
        { cardname: "月卡30D（单店使用）", amount: 99, days: 30, weight: 20 },
        { cardname: "21天卡（全国通用）", amount: 169, days: 21, weight: 60 },
        { cardname: "季卡90D（单店使用）", amount: 399, days: 90, weight: 3 },
        { cardname: "半年卡180D（单店使用）", amount: 599, days: 180, weight: 3 },
        { cardname: "年卡365D（全国通用）", amount: 999, days: 365, weight: 4 }
      ] // 卡和金额列表，新增权重字段
    };
  },
  created() {
    this.extendMockRandom(); // 扩展 Mock 随机函数
    this.GetStore(); // 获取店铺列表
  },
  computed: {
    // 格式化 JSON 数据
    formattedJson() {
      return JSON.stringify(this.jsonData, null, 2); // 缩进为2
    }
  },
  methods: {
    // 扩展 Mock.js 的随机函数
    extendMockRandom() {
      const vm = this; // 缓存 Vue 实例，避免作用域问题
      Mock.Random.extend({
        orderNo() {
          const timestamp = new Date().getTime(); // 当前时间戳
          const randomNum = Math.floor(Math.random() * 1000); // 随机数
          return `${timestamp}${randomNum.toString().padStart(3, "0")}`;
        },
        phone() {
          const prefix = ["139", "138", "137", "136", "135"]; // 常见手机号前缀
          const randomPrefix = prefix[Math.floor(Math.random() * prefix.length)];
          const randomSuffix = Math.random().toString().slice(2, 11); // 随机9位数字
          return `${randomPrefix}${randomSuffix}`;
        },
        storeName() {
          if (!vm.storelist || vm.storelist.length === 0) {
            console.warn("店铺列表为空，返回默认值 '未知店铺'");
            return "未知店铺";
          }
          const randomIndex = Math.floor(Math.random() * vm.storelist.length);
          return vm.storelist[randomIndex].storename;
        },
        cardInfo() {
          const weightedCards = vm.cardList.flatMap(card =>
            Array(card.weight).fill(card)
          ); // 根据权重展开卡片列表
          const randomIndex = Math.floor(Math.random() * weightedCards.length);
          return weightedCards[randomIndex];
        }
      });
    },
    // 生成最近一个月的随机日期
    randomRecentDate() {
      const now = new Date();
      const startOfMonth = new Date();
      startOfMonth.setDate(now.getDate() - 10); // 最近一个月的开始日期
      const randomDate = new Date(
        startOfMonth.getTime() +
          Math.random() * (now.getTime() - startOfMonth.getTime())
      );
      return randomDate.toISOString().slice(0, 19).replace("T", " "); // 格式化为 "YYYY-MM-DD HH:mm:ss"
    },
    // 根据开始日期和卡片天数计算结束日期
    calculateEndDate(startDate, days) {
      const start = new Date(startDate);
      start.setDate(start.getDate() + days);
      return start.toISOString().slice(0, 19).replace("T", " ");
    },
    // 使用 Mock.js 生成数据
    async generateMockData() {
      this.jsonData = Mock.mock({
        "data|100": [
          {
            "id|+1": 1, // 自增ID
            orderNo: "@orderNo", // 调用自定义订单号生成器
            username: "@phone", // 随机手机号
            storename: "@storeName", // 随机店名
            cardinfo: "@cardInfo", // 随机卡信息
            status: "@pick(['0', '1'])", // 随机订单状态
            cardfrom: "@pick(['1', '2'])",
            extra: "@orderNo",
            startdate: this.randomRecentDate() // 开始日期
          }
        ]
      }).data.map((item) => {
        // 计算结束日期并添加到每条记录中
        const cardInfo = item.cardinfo;
        const startDate = item.startdate;
        const endDate = this.calculateEndDate(startDate, cardInfo.days);
        return {
          ...item,
          date: startDate, // date 与 startdate 相同
          enddate: endDate // 根据 startdate 和卡天数计算
        };
      });

      // 按开始日期降序排列
      this.jsonData.sort((a, b) => new Date(b.startdate) - new Date(a.startdate));

      console.log("生成的订单数据（最近一个月，按日期降序排列）：", this.jsonData);
    },
    // 异步获取店铺列表
    async GetStore() {
      try {
        const { data: res } = await this.$http.get("store/");
        if (res.status === 0) {
          // 假设返回格式为 [{ id: 1, storename: '店铺1' }, { id: 2, storename: '店铺2' }]
          this.storelist = res.data;
          this.storeMap = res.data.reduce((acc, store) => {
            acc[store.storeid] = store.storename;
            return acc;
          }, {});
          console.log("获取的店铺列表：", this.storelist);
          this.generateMockData(); // 成功获取店铺列表后生成 Mock 数据
        } else {
          console.error("获取店铺数据失败：", res.message);
        }
      } catch (error) {
        console.error("获取店铺接口出错：", error);
      }
    }
  }
};
</script>

<style scoped>
pre {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
}
</style>
