<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>运营管理</el-breadcrumb-item>
            <el-breadcrumb-item>日志追踪</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-input placeholder="请输入用户名/内容" clearable v-model="queryInfo.query" @clear="getList()" style="width: 300px;">
                        <el-button slot="append" icon="el-icon-search" @click="getList()"></el-button>
                    </el-input>
                    <el-select  v-model="queryInfo.query1" clearable  placeholder="请选择类型" @change="getList()"  style="padding-left: 10px;">
                            <el-option label="抖音扫码" value="抖音扫码"></el-option>
                            <el-option label="抖音验劵" value="抖音验劵"></el-option>
                            <el-option label="美团验劵" value="美团验劵"></el-option>
                    </el-select>
                </el-col>
            </el-row>
            <br>
            <!--搜索与添加区域-->
            <!--商品列表区域-->
            <el-table :data="list" :key="list.index" style="width: 100%" stripe size="middle" height="700">
                <el-table-column label="ID" type="index"  width="50"></el-table-column>
                <el-table-column label="用户" prop="username"  width="150"></el-table-column>
                <el-table-column label="类型" prop="type"  width="200"></el-table-column>
                <el-table-column label="内容" prop="content"  width="900" show-overflow-tooltip></el-table-column>                
                <el-table-column label="日期" prop="adddate" ></el-table-column>
                </el-table>
            <!--商品列表区域-->
             <!--分页区域-->
             <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--分页区域-->
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return{
            queryInfo: {
                query: '',
                query1:'',
                pagenum: 1,
                pagesize: 10
            },
            total:0,
            list: [],
            admin_token:''
        }
        
    },
    created() {
         this.getList()
         this.admin_token = window.sessionStorage.getItem('admin_token') 
    },
    
    methods:{
        async getList() {
            const { data:res } = await this.$http.get('tracelist/',{ params: this.queryInfo })
            if(res.status == 1){
                this.total = res.data.totalCount;
                this.list = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                this.list[index].id= res.data.list[index].pk;
            }
        },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getList()
       }
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    el-table__row>td {
    border: none;
    }

    .el-table::before {
        height: 0px;
    }

</style>