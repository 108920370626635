<template>
    <el-container class="home-container">
        <el-aside width="230px">
            <el-menu default-active="2" background-color="#020e24" text-color="#ecedee" active-text-color="#c7401d" unique-opened router  style="height: 100%;">
                <el-menu-item>
                    <div style="padding: 10px 20px;color:#c7401d;font-weight: bold;font-size: 20px;">
                        <img src="../assets/image/storelogo.png" style="height: 30px;vertical-align: middle;border-radius: 10px;"></img>
                        {{name_token}}
                    </div>
                </el-menu-item>
                <el-menu-item>
                    <div style="color:#c7401d;font-weight: bold;">共享健身后台管理系统</div>
                </el-menu-item>

                <el-submenu index="0" v-if="hasPermission('财务统计')">
                    <template slot="title">
                    <i class="el-icon-s-data"></i>
                    <span>财务统计</span>
                    </template>
                    <el-menu-item index="/welcome" @click="saveNavState('/welcome')"><i class="el-icon-menu"></i>财务统计数据</el-menu-item>
                </el-submenu>
                <el-submenu index="1" v-if="hasPermission('门店管理')">
                    <template slot="title">
                    <i class="el-icon-s-home"></i>
                    <span>门店管理</span>
                    </template>
                    <el-menu-item index="/stores" @click="saveNavState('/stores')"><i class="el-icon-menu"></i>门店列表</el-menu-item>
                    <el-menu-item index="/swiper"  @click="saveNavState('/swiper')"><i class="el-icon-menu"></i>轮播图设置</el-menu-item>
                </el-submenu>
                <el-submenu index="2" v-if="hasPermission('加盟商管理')">
                    <template slot="title">
                    <i class="el-icon-user-solid"></i>
                    <span>加盟商管理</span>
                    </template>
                    <el-menu-item index="/merchants" @click="saveNavState('/merchants')"><i class="el-icon-menu"></i>加盟商列表</el-menu-item>
                    <el-menu-item index="/inexplist" @click="saveNavState('/inexplist')"><i class="el-icon-menu"></i>收支明细</el-menu-item>
                    <!-- <el-menu-item index="/cashlist" @click="saveNavState('/cashlist')"><i class="el-icon-menu"></i>提现管理</el-menu-item> -->
                </el-submenu>
                <el-submenu index="3" v-if="hasPermission('会员管理')">
                    <template slot="title">
                    <i class="el-icon-user"></i>
                    <span>会员管理</span>
                    </template>
                    <el-menu-item index="/users" @click="saveNavState('/users')"><i class="el-icon-menu"></i>会员列表</el-menu-item>
                    <el-menu-item index="/records" @click="saveNavState('/records')"><i class="el-icon-menu"></i>进出记录</el-menu-item>
                </el-submenu>
                <el-submenu index="4" v-if="hasPermission('会员卡管理')">
                    <template slot="title">
                    <i class="el-icon-postcard"></i>
                    <span>会员卡管理</span>
                    </template>
                    <el-menu-item index="/cards"  @click="saveNavState('/cards')"><i class="el-icon-menu"></i>会员卡列表</el-menu-item>
                </el-submenu>
                <el-submenu index="5" v-if="hasPermission('订单管理')">
                    <template slot="title">
                    <i class="el-icon-s-order"></i>
                    <span>订单管理</span>
                    </template>
                    <el-menu-item index="/orders"  @click="saveNavState('/orders')"><i class="el-icon-menu"></i>订单列表</el-menu-item>
                </el-submenu>
                <el-submenu index="6" v-if="hasPermission('开店信息')">
                    <template slot="title">
                    <i class="el-icon-phone"></i>
                    <span>开店信息</span>
                    </template>
                    <el-menu-item index="/openinfo"  @click="saveNavState('/openinfo')"><i class="el-icon-menu"></i>信息列表</el-menu-item>
                </el-submenu>
                <el-submenu index="7" v-if="hasPermission('助教管理')">
                    <template slot="title">
                    <i class="el-icon-s-custom"></i>
                    <span>助教管理</span>
                    </template>
                    <el-menu-item index="/coachs"  @click="saveNavState('/coachs')"><i class="el-icon-menu"></i>助教列表</el-menu-item>
                </el-submenu>
                <el-submenu index="8" v-if="hasPermission('系统管理')">
                    <template slot="title">
                    <i class="el-icon-s-platform"></i>
                    <span>系统管理</span>
                    </template>
                    <el-menu-item index="/managers"  @click="saveNavState('/managers')"><i class="el-icon-menu"></i>设置管理员</el-menu-item>
                    <el-menu-item index="/systems"  @click="saveNavState('/systems')"><i class="el-icon-menu"></i>协议设置</el-menu-item>
                    <el-menu-item index="/logs"  @click="saveNavState('/logs')"><i class="el-icon-menu"></i>日志管理</el-menu-item>
                </el-submenu>
                <el-submenu index="9" v-if="hasPermission('运营管理')">
                    <template slot="title">
                    <i class="el-icon-chat-dot-round"></i>
                    <span>运营管理</span>
                    </template>
                    <el-menu-item index="/messages"  @click="saveNavState('/messages')"><i class="el-icon-menu"></i>留言管理</el-menu-item>
                    <el-menu-item index="/faults"  @click="saveNavState('/faults')"><i class="el-icon-menu"></i>设备报修管理</el-menu-item>
                </el-submenu>
                <el-submenu index="10"  v-if="hasPermission('拓客管理')">
                    <template slot="title">
                    <i class="el-icon-connection"></i>
                    <span>拓客管理</span>
                    </template>
                    <el-menu-item index="/differ"  @click="saveNavState('/differ')"><i class="el-icon-menu"></i>异业合作</el-menu-item>
                </el-submenu>
                <el-menu-item @click="openNewWindow('http://8.130.39.76:18083/')" v-if="hasPermission('设备管理')">
                    <i class="el-icon-cpu"></i>
                    <span slot="title" >设备管理</span>
                </el-menu-item>
                <el-submenu index="11" v-if="hasPermission('虚拟订单管理')">
                    <template slot="title">
                    <i class="el-icon-s-order"></i>
                    <span>虚拟订单管理</span>
                    </template>
                    <el-menu-item index="/mcorders"  @click="saveNavState('/mcorders')"><i class="el-icon-menu"></i>订单列表</el-menu-item>
                </el-submenu>
            </el-menu>
        </el-aside>
        <el-container>
            <el-header>
                <div style="padding-left: 20px;color:#c7401d">
                    <!-- <i class="el-icon-message-solid"></i><span style="padding-left: 10px;">下面发布一则通知.....</span> -->
                    <!-- <el-button type="primary" icon="el-icon-search">搜索</el-button>
                    <i class="el-icon-search" color="#ff0000"></i>
                    <span style="font-size:25px;color:#c7401d;padding-left:20px">共享健身后台管理系统--{{name_token}}</span> -->
                </div>
                <div>
                    
                    <el-button @click="editDialogVisible = true">修改密码</el-button>
                    <el-button type="" @click="LogOut">退出</el-button>
                </div>
            </el-header>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
        <!--修改密码对话框-->
        <el-dialog title="修改密码" :visible.sync="editDialogVisible" width="40%" @close="editDialogClosed">
            <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
                <el-form-item label="原密码" prop="password">
                    <el-input v-model="editForm.password" show-password></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="new_password">
                    <el-input v-model="editForm.new_password" show-password></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="con_password">
                    <el-input v-model="editForm.con_password" show-password></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editPass">确 定</el-button>
            </span>
        </el-dialog>
        <!--修改密码对话框-->
    </el-container>
</template>
<script>
export default {
    data(){
        //验证原密码规则
        // var oldPass = (rule, value, callback) => {
        //     if (value !== this.old_password) {
        //     callback(new Error('原密码不正确!'));
        //     } else {
        //     callback();
        //     }
        // }
        //验证确认密码规则
        var validatePass = (rule, value, callback) => {
            if (value !== this.editForm.new_password) {
            callback(new Error('两次输入密码不一致!'));
            } else {
            callback();
            }
        }
        return {
            isCollapse: false,
            activePath: '',
            editDialogVisible:false,
            old_password:'',
            editForm:{
                 password:'',
                 new_password:'',
                 con_password:''
            },
            editFormRules: {
                password: [
                    { required: true, message: '请输入原密码', trigger: 'blur' },
                    // { validator: oldPass, trigger: 'blur'}
                ],
                new_password: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
                ],
                con_password:[
                    { required: true, message: '请输入确认密码', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur'}
                ],
            }
        }
    },
    created() {
        this.activePath = window.sessionStorage.getItem('activePath')
        this.admin_token = window.sessionStorage.getItem('admin_token')
        this.name_token = window.sessionStorage.getItem('name_token')
        this.role_token = window.sessionStorage.getItem('role_token')
        this.storeid_token = window.sessionStorage.getItem('storeid_token')
        this.perid_token = window.sessionStorage.getItem('perid_token')
        
        this.old_password = window.sessionStorage.getItem('pass_token')
	},
    methods:{
        saveNavState(activePath) {
			window.sessionStorage.setItem('activePath', activePath)
			this.activePath = activePath
			
        },
        LogOut(){
            window.sessionStorage.clear();
            this.$router.push('/admin');
        },
        editDialogClosed(){
           this.$refs.editFormRef.resetFields()
       },
       editPass(){
           this.$refs.editFormRef.validate( async valid => {
                if(!valid) return;
                const { data: res } = await this.$http.post('editpass/',{username:this.admin_token,oldpwd:this.editForm.password,password:this.editForm.new_password})
                if (res.status === 0) {
                    this.$message.success('密码修改成功！')
                    this.editDialogVisible = false
                }
                if (res.status === 1) {
                    this.$message.error('旧密码不正确！')
                }
            })
       },
       Print(){
           console.log('3333333333333')
       },
       openNewWindow(url) {
            window.open(url, '_blank');
       },
       hasPermission(permission) {
            // 将字符串类型的 perid_token 转为数组
            const peridArray = this.perid_token.replace(/'/g, '"'); // 替换单引号为双引号
            const peridList = JSON.parse(peridArray); // 转换为数组

            // 检查权限是否存在
            return peridList.includes(permission);
        }
    }
}
</script>
<style>
    .home-container{
        padding-top:0px;
        height: 100%;
    }
    .el-header{
        background-color: #ffffff;
        /* background-image: linear-gradient(to right,#6f82e1,#5165f8); */
        display: flex;
        justify-content: space-between;
        padding-left: 100px;
        align-items: center;
        font-size: 20px;
        color: #fff;
        height: 60px !important;
    }
    .el-aside{
	    background-color: #fff;
    }
    .el-main {
        background-color:#FAFAFA;
    }
    .el-menu {
		border-right: none;
    }
    .el-submenu :hover{
        background-color: #010101 !important;
    }
    .el-submenu__title,.el-dropdown-menu__item, .el-menu-item{
        font-size: 16px !important;
    }
</style>