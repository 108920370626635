<script>
    // const imgURL = 'https://jianshenhtapi.girlfee.com/';
    // const imgURL_Front = 'https://jianshenhtapi.girlfee.com/';
    const imgURL='https://jianshenhtapi.tieshouyundong.com/';
    const imgURL_Front = 'https://jianshenapi.tieshouyundong.com/';
    
    
    export default
    {
        imgURL,
        imgURL_Front
    }
</script>