<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>拓客管理</el-breadcrumb-item>
            <el-breadcrumb-item>异业合作</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-input placeholder="请输入合作门店名称" clearable v-model="queryInfo.query" @clear="getList()" style="width: 300px;margin: 0 10px;">
                        <el-button slot="append" icon="el-icon-search" @click="getList()"></el-button>
                    </el-input>
                    <el-button type="success" icon="el-icon-s-home" @click="AddDiffer">添加合作门店</el-button>
                </el-col>
            </el-row>
            <br>
            <!--搜索与添加区域-->
            <!--用户列表区域-->
            <el-table :data="list"  :key="list.index" style="width: 100%" stripe size="middle" height="700">
                <el-table-column label="ID" width="50">
                    <template slot-scope="scope">
                        {{ (queryInfo.pagenum - 1) * queryInfo.pagesize + scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column label="合作店名" prop="differname" width="200" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="手机号" prop="mobile" width="150"></el-table-column>
                <el-table-column label="健身门店" prop="storename" width="200" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="会员卡" prop="cardname" width="200" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="有效时间段" prop="storename" width="300">
                    <template slot-scope="scope">
                        {{ scope.row.startdate }} 至 {{ scope.row.enddate }}
                    </template>
                </el-table-column>
                <el-table-column label="地址" prop="address" width="200" :show-overflow-tooltip="true"></el-table-column>             
                <el-table-column label="添加时间" prop="adddate" width="180"></el-table-column>
                <el-table-column label="操作" width="290" fixed="right">
                    <!--作用域插槽-->
                    <template slot-scope="scope"> 
                        <el-button type="primary" icon="el-icon-edit" size="mini" @click="EditDiffer(scope.row.id)"></el-button>
                        <el-button type="danger" icon="el-icon-full-screen" size="mini" @click="QrCodeCard(scope.row.url,scope.row.differname,scope.row.enddate)">分享二维码</el-button>
                        <el-button type="warning" icon="el-icon-share" size="mini" @click="ShareList(scope.row.mobile,scope.row.differname)">分享记录</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--用户列表区域-->
            <!--分页区域-->
            <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--分页区域-->
            <!--添加用户对话框-->
            <el-dialog title="添加合作门店" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
                <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
                    <el-form-item label="合作店名" prop="differname">
                        <el-input v-model="addForm.differname"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="mobile">
                        <el-input v-model="addForm.mobile"  placeholder="手机号输入之后不能修改，做为统计的唯一标识"></el-input>
                    </el-form-item>
                    <el-form-item label="门店地址" prop="address">
                        <el-input v-model="addForm.address"></el-input>
                    </el-form-item>
                    <el-form-item label="会员卡" prop="cardid">
                        <el-select  v-model="addForm.cardid"  placeholder="请选择会员卡" style="width:300px">
                            <el-option v-for="item in cardlist" :key="item.id" :label="item.cardname+'  ￥'+item.cardprice" :value="item.cardid"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="有效时间段" prop="effectivetdate">
                        <el-date-picker v-model="addForm.effectivetdate"
                        type="daterange"
                        :picker-options="pickerOptions"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addDifferSave">确 定</el-button>
                </span>
            </el-dialog>
            <!--添加用户对话框-->
            <!--编辑用户对话框-->
            <el-dialog title="编辑合作门店" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
                <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
                    <el-form-item label="手机号" prop="mobile">
                        {{editForm.mobile}}
                    </el-form-item>
                    <el-form-item label="合作店名" prop="differname">
                        <el-input v-model="editForm.differname"></el-input>
                    </el-form-item>
                    <el-form-item label="门店地址" prop="address">
                        <el-input v-model="editForm.address"></el-input>
                    </el-form-item>
                    <el-form-item label="会员卡" prop="cardid">
                        <el-select  v-model="editForm.cardid"  placeholder="请选择会员卡" style="width:300px">
                            <el-option v-for="item in cardlist" :key="item.id" :label="item.cardname+'  ￥'+item.cardprice" :value="item.cardid"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="有效时间段" prop="effectivetdate">
                        <el-date-picker v-model="editForm.effectivetdate"
                        type="daterange"
                        :picker-options="pickerOptions"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="editDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="EditDifferSave">确 定</el-button>
                </span>
            </el-dialog>
            <!--编辑用户对话框-->

            <!--分享二维码对话框-->
            <el-dialog title="分享二维码" :visible.sync="qrcodeDialogVisible" width="50%">
                <el-card style="text-align: center;">
                    <canvas ref="qrcodeCanvas"></canvas>
                    <div style="padding-top: 20px;font-size: 18px;">{{ differname }}推广二维码</div>
                    <div style="padding-top: 20px;">
                        <el-button type="success" icon="el-icon-download" @click="DowLoad()">点击下载</el-button>
                    </div>
                </el-card>
            </el-dialog>
            <!--分享二维码对话框-->
            <!--分享记录对话框-->
            <el-dialog title="分享记录" :visible.sync="shareDialogVisible" width="85%">
                <div style="text-align: right;padding-bottom: 10px;">
                    <download-excel :data = "sharelist" :fields = "excel_fields" :name = "excel_name">
                        <el-button type="success" icon="el-icon-upload">导出Excel</el-button>
                    </download-excel>
                </div>
                <el-card style="text-align: center;">
                    <el-card>
                    <el-table :data="sharelist"  :key="sharelist.id" style="width: 100%" stripe size="small" height="500">
                        <el-table-column label="ID" type="index" width="50"></el-table-column>
                        <el-table-column label="单号" prop="orderno" width="150"></el-table-column>
                        <el-table-column label="用户" prop="username" width="120"></el-table-column>
                        <el-table-column label="卡名" prop="cardname" width="300" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                <div style="display: flex;">
                                    <div style="padding-right: 5px;line-height: 30px;">
                                        {{ scope.row.cardname }}
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="健身门店" prop="storename" width="300" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="开始日期" prop="starttime" width="150"></el-table-column>
                        <el-table-column label="结束日期" prop="endtime" width="150"></el-table-column>
                        <el-table-column label="状态" prop="status"  width="100">
                            <template slot-scope="scope">
                                <el-tag type="success" v-if="scope.row.status == '0'">有效</el-tag>
                                <el-tag type="info" v-if="scope.row.status == '1'">失效</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="领取日期" prop="adddate" ></el-table-column>
                    </el-table>
                </el-card>
                </el-card>
            </el-dialog>
            <!--分享记录对话框-->
        </el-card>
    </div>
</template>
<script>
import axios from 'axios';
import QRCode from 'qrcode';
import logo from "@/assets/image/storelogo.png";
export default {
    
    data() {
        //验证手机的规则
        var checkMobile = (rule,value,cb) => {
                const regMobile = /^1([38]\d|5[0-35-9]|7[3678])\d{8}$/
                if(regMobile.test(value)){
                    return cb()
                }
                cb(new Error('请输入合法的手机号！'))
        }
        return{
            logoSrc: logo, // 替换为你的 Logo 图片路径
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            queryInfo: {
                query: '',
                pagenum: 1,
                pagesize: 10
            },
            total:0,
            list: [],
            cardlist:[],
            sharelist:[],
            addDialogVisible:false,
            editDialogVisible:false,
            qrcodeDialogVisible:false,
            shareDialogVisible:false,
            addForm:{
                differname:'',
                mobile:'',
                address:'',
                storeid:'',
                cardid:'',
                effectivetdate:''
            },
             //编辑表单数据
             editForm:{
                differname:'',
                address:'',
                storeid:'',
                cardid:'',
                effectivetdate:''
            },
            
            //添加表单验证规则
            addFormRules:{
                differname:[
                    { required: true, message: '请输入合作店名', trigger: 'blur' }
                ],
                mobile:[
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { validator: checkMobile, trigger: 'blur'}
                ],
                cardid:[
                    { required: true, message: '请选择会员卡', trigger: 'blur' }
                ],
                effectivetdate:[
                    { required: true, message: '请选择有效时间段', trigger: 'blur' }
                ]
                
            },
            editFormRules:{
                differname:[
                    { required: true, message: '请输入合作店名', trigger: 'blur' }
                ],
                cardid:[
                    { required: true, message: '请选择会员卡', trigger: 'blur' }
                ],
                effectivetdate:[
                    { required: true, message: '请选择有效时间段', trigger: 'blur' }
                ]
            },
            storeid:'',
            differname:'',
            excel_name:'',
            exclelist:[],
            excel_fields: {  //导出Excel表格的表头设置
                // '单号': 'orderno',
                '单号':{
                    field:'orderno',
                    callback:value => {
                        return '&nbsp;'+value;
                    } 
                },
                '用户':'username',
                '卡名':'cardname',
                '健身门店':'storename',
                '开始日期': 'starttime',
                '结束日期': 'endtime',
                '状态': 'status',
                '领取日期': 'adddate'
            }
        }
        
    },
    created() {
        this.queryInfo.storeid = window.sessionStorage.getItem('joinstoreid_token'),//门店ID
        this.getList()
    },
    mounted(){
        this.addForm.admin_token = window.sessionStorage.getItem('join_token') 
        this.addForm.storeid = this.queryInfo.storeid
        this.getCardList(this.queryInfo.storeid)
    },
    methods:{
        async getCardList(storeid){
            const { data: res } = await this.$http.get('storecard/',{ params: {storeid: storeid} })
            this.cardlist = res.data
        },
        async getList() {
           const { data: res} = await this.$http.get('differlist/',{ params: this.queryInfo })
           if(res.status == 1){
                this.total = res.data.totalCount;
                this.list = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                this.list[index].id= res.data.list[index].pk;
            }
        },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getList()
       },
       addDialogClosed(){
            this.$refs.addFormRef.resetFields()
       },
       AddDiffer(){
            this.addDialogVisible = true
       },
       async addDifferSave(){
            // console.log(this.addForm);
              this.$refs.addFormRef.validate( async valid=>{
                if (!valid) return
                const { data:res } = await this.$http.post('differadd/',this.addForm)
                if(res.status===0)
                {
                    this.$message.success('添加合作门店成功！')
                    this.addDialogVisible = false
                    this.getList()
                }
                if(res.status == 1){
                    this.$message.error('此合作门店已存在！')
                }
              })
       },
       async EditDiffer(id){
            const { data: res } = await this.$http.get('differinfo/'+id)
            this.editForm = res
            this.editForm.effectivetdate = [res.startdate, res.enddate]
            this.editForm.admin_token = window.sessionStorage.getItem('join_token') 
            this.editDialogVisible = true
       },
       EditDifferSave(){
           this.$refs.editFormRef.validate(async valid => {
                if (!valid) return
                const { data: res } = await this.$http.post('differedit/' + this.editForm.id,this.editForm)
                if (res.status === 0) {
                    this.$message.success('合作门店修改成功')
                    this.editDialogVisible = false
                    this.getList()
                }
            })
       },
       editDialogClosed(){
            this.$refs.editFormRef.resetFields()
       },
       generateQRCode(url) {
            // 确保 DOM 已渲染
            this.$nextTick(() => {
                const canvas = this.$refs.qrcodeCanvas;
                const options = {
                width: 300,
                margin: 1,
                errorCorrectionLevel: "H",
                color: {
                    dark: "#000000",
                    light: "#ffffff",
                },
                };

                // 生成二维码
                QRCode.toCanvas(canvas, url, options, (error) => {
                if (error) {
                    console.error("二维码生成失败:", error);
                    return;
                }

                // 添加 Logo
                this.addLogoToQRCode();
                });
            });
      },
      addLogoToQRCode() {
        const canvas = this.$refs.qrcodeCanvas;
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.src = this.logoSrc;

        img.onload = () => {
            const qrSize = canvas.width;
            const logoSize = qrSize * 0.2;
            const logoX = (qrSize - logoSize) / 2;
            const logoY = (qrSize - logoSize) / 2;
            const radius = logoSize * 0.2;

            // 绘制圆角背景
            ctx.beginPath();
            ctx.moveTo(logoX + radius, logoY);
            ctx.arcTo(logoX + logoSize, logoY, logoX + logoSize, logoY + logoSize, radius);
            ctx.arcTo(logoX + logoSize, logoY + logoSize, logoX, logoY + logoSize, radius);
            ctx.arcTo(logoX, logoY + logoSize, logoX, logoY, radius);
            ctx.arcTo(logoX, logoY, logoX + logoSize, logoY, radius);
            ctx.closePath();
            ctx.fillStyle = "#ffffff";
            ctx.fill();

            // 绘制 Logo
            ctx.clip();
            ctx.drawImage(img, logoX, logoY, logoSize, logoSize);
        };
    },
    QrCodeCard(url,differname,enddate){
        // 获取当前日期
        const currentDate = new Date();
        // 将 enddate 转换为 Date 对象
        const endDate = new Date(enddate);

        // 判断 enddate 是否小于当前日期
        if (endDate < currentDate) {
            // 弹框提示二维码过期
            this.$message.error("活动结束，二维码已过期！");
            return; // 直接返回，不执行后续逻辑
        }
        console.log(enddate);
        this.qrcodeDialogVisible = true
        this.generateQRCode(url)
        this.differname = differname
    },
    DowLoad(storename) {
      const canvas = this.$refs.qrcodeCanvas;

      // 检查 Canvas 是否存在
      if (!canvas) {
        this.$message.error("未找到二维码！");
        return;
      }

      // 获取二维码图像的 Base64 数据
      const url = canvas.toDataURL("image/png");

      // 创建一个临时链接
      const a = document.createElement("a");
      a.href = url;
      a.download = this.differname+".png"; // 下载文件名
      a.style.display = "none";

      // 添加到 DOM 后触发点击事件，然后移除
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    async ShareList(mobile,differname){
        this.shareDialogVisible = true
        const { data: res} = await this.$http.get('sharelist/',{ params:{mobile:mobile} })
          if(res.status == 0){
            this.sharelist = res.data
            this.excel_name = differname+"的分享记录"
          }
      }
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    el-table__row>td {
    border: none;
    }

    .el-table::before {
    height: 0px;
    
    }
</style>