<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>订单列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-input placeholder="请输入订单编号/用户名/卡名" clearable v-model="queryInfo.query" @clear="getOrderList()" style="width: 300px;">
                        <el-button slot="append" icon="el-icon-search" @click="getOrderList()"></el-button>
                    </el-input>
                    <el-select  v-model="queryInfo.query2" clearable  placeholder="请选择交易类型" @change="getOrderList()"  style="margin-left:10px">
                            <el-option label="平台" value="0"></el-option>
                            <el-option label="抖音" value="1"></el-option>
                            <el-option label="美团" value="2"></el-option>
                            <el-option label="赠送" value="3"></el-option>
                            <el-option label="分享" value="4"></el-option>
                            <el-option label="异业" value="5"></el-option>
                    </el-select>
                    <el-select  v-model="queryInfo.query3" clearable  placeholder="是否失效" @change="getOrderList()"  style="margin: 0 10px">
                        <el-option label="有效" value="0"></el-option>
                        <el-option label="失效" value="1"></el-option>
                    </el-select>
                    <el-date-picker
                        v-model="customdate"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions" @change="CustomeDate">
                        </el-date-picker>
                </el-col>
            </el-row>
            <br>
            <!--搜索与添加区域-->
            <!--用户列表区域-->
            <el-table :data="orderlist" style="width: 100%" stripe size="small">
                <!-- <el-table-column label="ID" type="index"  width="50" fixed></el-table-column> -->
                <el-table-column label="序号" width="50" fixed>
                    <template slot-scope="scope">
                        {{ (queryInfo.pagenum - 1) * queryInfo.pagesize + scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column label="订单编号" prop="orderno" width="140"></el-table-column>
                <el-table-column label="用户名" prop="username" width="100"></el-table-column>
                <el-table-column label="门店名称" prop="storename" width="150" :show-overflow-tooltip="true"></el-table-column>
                <!-- <el-table-column label="卡名" prop="cardname" width="150" :show-overflow-tooltip="true"></el-table-column> -->
                <el-table-column label="卡名" prop="cardname" width="260" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div style="display: flex;">
                            <div style="padding-right: 5px;line-height: 30px;">
                                {{ scope.row.cardname }}
                            </div>
                            <div v-if="scope.row.cardclass == '体验卡'">
                                <el-tag type="danger" v-if="scope.row.istuikuan == '1'">退款</el-tag>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="金额" prop="amount" width="80">
                    <template slot-scope="scope" v-if="scope.row.amount">
                        <span style="color: #ff0000;">￥{{scope.row.amount}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="来源" prop="cardfrom" width="220">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.cardfrom == '0'">平台</el-tag>
                        <el-tag type="danger" v-if="scope.row.cardfrom == '1'">抖音</el-tag>
                        <el-tag type="warning" v-if="scope.row.cardfrom == '2'">美团</el-tag>
                        <el-tag type="primary" v-if="scope.row.cardfrom == '3'">赠送</el-tag>
                        <el-tag type="info" v-if="scope.row.cardfrom == '4'">分享</el-tag>
                        <el-tag type="info" v-if="scope.row.cardfrom == '5'" effect="dark">异业</el-tag>
                        <span v-if="scope.row.cardfrom == '1'" style="padding-left: 10px;">劵码：{{ scope.row.extra }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="开始时间" prop="starttime" width="150"></el-table-column> -->
                <el-table-column label="收款商户" prop="mchid" width="100"></el-table-column>
                <el-table-column label="状态" prop="status"  width="80">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.status == '0'">有效</el-tag>
                        <el-tag type="info" v-if="scope.row.status == '1'">失效</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="开始时间" prop="starttime" width="150"></el-table-column>
                <el-table-column label="截止时间" prop="endtime" width="150"></el-table-column>
                <el-table-column label="订单状态" prop="status" width="90">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.paytus==1" type="success">已支付</el-link>
                        <el-link v-else type="warning">未支付</el-link>
                    </template>
                </el-table-column>
                <el-table-column label="订单日期" prop="adddate" width="150" fixed="right"></el-table-column>
            </el-table>
            <!--用户列表区域-->
            <!--分页区域-->
            <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>
<script>
export default {
    data(){
        return{
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            customdate:'',
            //获取订单列表的参数对象
            queryInfo: {
                query:'',
                query1: window.sessionStorage.getItem('joinstoreid_token'),//门店ID
                query2: '',
                query3: '',
                startdate: '',
                enddate: '',
                pagenum: 1,
                pagesize: 10
            },
            orderlist: [],
            total: 0,
        }
    },
    created() {
         this.getOrderList()
    },
    methods:{
        async getOrderList() {
           const { data: res} = await this.$http.get('orderlist/',{ params: this.queryInfo })
           if(res.status == 1){
                this.total = res.data.totalCount;
                this.orderlist = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                    this.orderlist[index].id= res.data.list[index].pk;
                }
            console.log(this.orderlist)
       },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getOrderList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getOrderList()
       },
       CopyText(row){
            var oInput = document.createElement('input')
            oInput.value = row.cons_address +' '+ row.cons_name +' '+ row.cons_telephone
            document.body.appendChild(oInput)
            oInput.select()
            document.execCommand("Copy")
            oInput.className = 'oInput'
            oInput.style.display = 'none'
            this.$message.success('复制成功！')
        },
        async RevokeOrder(id){
            const confirmResult = await this.$confirm('是否要撤销此订单吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                        }).catch(err => err)

                        if (confirmResult !== 'confirm'){
                            return this.$message.info('已取消删除！')
                        }
                        const { data: res } = await this.$http.get('orderrevoke/'+ id)
                        // console.log(res)
                        if (res.status === 0) { 
                            this.$message({message: '撤销成功！',type: 'success'})
                        }
                        this.getOrderList()
        },
       async DelOrder(id){
                const confirmResult = await this.$confirm('是否要删除此订单吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).catch(err => err)

                    if (confirmResult !== 'confirm'){
                        return this.$message.info('已取消删除！')
                    }
                    const { data: res } = await this.$http.get('orderdel/'+ id)
                    // console.log(res)
                    if (res.status === 0) { 
                        this.$message({message: '删除成功！',type: 'success'})
                    }
                    this.getOrderList()
       },
       async Checked(id,send_sign){
          const { data: res} = await this.$http.get('checksend/',{ params:{ id:id,sign:send_sign }})
          if(res.status === 0){
             this.$message({message: '发货审核通过！',type: 'success'}) 
             this.getOrderList()
          }
          if(res.status === 1){
             this.$message({message: '发货审核取消！',type: 'warning'}) 
             this.getOrderList()
          }
      },
      CustomeDate(){
            // console.log(this.customdate);
            if(this.customdate == null){
                this.queryInfo.startdate = ""
                this.queryInfo.enddate = ""
            }else{
                this.queryInfo.startdate = this.customdate[0]
                this.queryInfo.enddate = this.customdate[1]
            }
            this.getOrderList()
        }
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
</style>