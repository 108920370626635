import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/style.css'
import global_ from './components/common/Global'//引用全局变量
Vue.prototype.GLOBAL = global_//挂载到Vue实例上面
//导入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'

//导入富文本css
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

// js跨域请求插件
import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)

import Myeditor from "./components/common/MyEditor.vue"   //引入组件
Vue.component("myeditor",Myeditor);
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import axios from 'axios'

axios.defaults.baseURL = 'https://jianshenhtapi.tieshouyundong.com/'
// axios.defaults.baseURL = 'https://jianshenhtapi.girlfee.com/'

axios.interceptors.request.use(config => {
  return config
})
Vue.config.productionTip = false
Vue.prototype.$http = axios
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
