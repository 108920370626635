<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/stores' }">门店管理</el-breadcrumb-item>
            <el-breadcrumb-item>编辑门店</el-breadcrumb-item>
        </el-breadcrumb>
        <!--卡片视图区-->
        <el-card>
            <el-form :model="editForm" :rules="editFormRules" ref="editFormRef"  label-width="100px" style="width:90%;margin:10px auto">
                    <el-form-item label="门店名称" prop="storename">
                        {{ editForm.storename}}
                            <!-- <el-input v-model="editForm.storename"></el-input> -->
                    </el-form-item>    
                    <!-- <el-form-item label="所属城市" prop="city">
                        <el-cascader v-model="editForm.city" :options="citylist"></el-cascader>
                    </el-form-item> -->
                    
                    <!-- <el-form-item label="店铺图片" prop="storepic">
                        <el-upload
                            ref="imgBroadcastUpload"
                            :limit="limitNum"
                            :auto-upload="true"
                            :file-list="fileList"
                            :on-success="editPic"
                            list-type="picture-card"
                            accept="image/jpg"
                            :action="uploadurl">
                                <i class="el-icon-plus"></i>
                                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2M</div>
                        </el-upload>
                    </el-form-item> -->
                    <!-- <el-form-item label="门店地址" prop="address">
                        <el-input v-model="editForm.address" style="width:600px"  clearable @input="addressChange(15)"></el-input>
                    </el-form-item> -->
                    <!-- <el-form-item label="门店定位">
                        <el-input v-model="editForm.localtion" style="width:600px"></el-input>
                    </el-form-item> -->
                    <!-- <el-form-item label="门店定位">
                        <tx-map ref="map" @setAddress="setAddress" />
                    </el-form-item> -->
                    
                    <el-form-item label="门店简介" prop="jianjie">
                        <el-input v-model="editForm.jianjie" type="textarea" :rows="3" maxlength="100" show-word-limit style="width:600px"></el-input>
                    </el-form-item>
                    <el-form-item label="操房数量" prop="roomnum">
                        <el-input v-model="editForm.roomnum"  style="width:300px"></el-input> 间
                    </el-form-item>
                    <el-form-item label="门店面积" prop="storearea">
                        <el-input v-model="editForm.storearea"  style="width:300px"></el-input> ㎡
                    </el-form-item>
                    <el-form-item label="器械种类" prop="devicetype">
                        <el-input v-model="editForm.devicetype"  style="width:300px"></el-input> 种
                    </el-form-item>
                    <el-form-item label="客服微信" prop="wechat">
                        <el-input v-model="editForm.wechat"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="客服电话" prop="telephone">
                        <el-input v-model="editForm.telephone"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="店内Wifi" prop="wifi">
                        <el-input v-model="editForm.wifi"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="进店1Sn" prop="sn">
                        <el-input v-model="editForm.sn"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="出店1Sn" prop="outsn">
                        <el-input v-model="editForm.outsn"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="进店2Sn" prop="sn1">
                        <el-input v-model="editForm.sn1"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="出店2Sn" prop="outsn1">
                        <el-input v-model="editForm.outsn1"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="鱼眼1Sn" prop="fishsn">
                        <el-input v-model="editForm.fishsn"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="鱼眼2Sn" prop="fishsn1">
                        <el-input v-model="editForm.fishsn1"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="摄像头1Sn" prop="camera1">
                        <el-input v-model="editForm.camera1"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="摄像头2Sn" prop="camera2">
                        <el-input v-model="editForm.camera2"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="摄像头3Sn" prop="camera3">
                        <el-input v-model="editForm.camera3"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="摄像头4Sn" prop="camera4">
                        <el-input v-model="editForm.camera4"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="边缘盒子Sn" prop="boxsn">
                        <el-input v-model="editForm.boxsn"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="温湿度Sn" prop="wsdsn">
                        <el-input v-model="editForm.wsdsn"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="抖音门店ID" prop="dystoreid">
                        <el-input v-model="editForm.dystoreid"  style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="isopen">
                        <el-select  v-model="editForm.isopen"  placeholder="请选择类型" style="width:300px">
                            <el-option v-for="item in statuslist" :key="item.id" :label="item.status" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="EditStoreSave">确 定</el-button>
                        <el-button >取 消</el-button>
                    </el-form-item>
                </el-form>
        </el-card>
    </div>
</template>
<script>
// import TxMap from "../../components/common/TXMap"
import { cities } from '@/assets/js/city.js'
export default {
    // components: { TxMap },
    data(){
        return {
            imgURL:this.GLOBAL.imgURL,
            uploadurl: this.GLOBAL.imgURL+'upload/',
            limitNum: 1,
            fileList:[],
            cities:cities,
            citylist:[],
            statuslist:[],
            id:'',
            //编辑表单数据
            editForm:{
                // storename:'',
                // city:'',
                // address:'东营市',
                // localtion:'',
                // prov_name: "",
                // city_name: "",
                // district_name: "",
                // latitude:'',
                // longitude:'',
                // storepic:'',
                roomnum:'',
                storearea:'',
                devicetype:'',
                wechat:'',
                telephone:'',
                wifi:'',
                jianjie:'',
                isopen:1,
                sn:'',
                outsn:'',
                sn1:'',
                outsn1:'',
                camera1:'',
                camera2:'',
                camera3:'',
                camera4:'',
                fishsn:'',
                fishsn1:'',
                boxsn:'',
                wsdsn:''
            },
            //编辑表单验证规则
            editFormRules:{
                storename:[
                    { required: true, message: '请选输入门店名称', trigger: 'blur' }
                ],
                city:[
                    { required: true, message: '请选择所属城市', trigger: 'blur' }
                ],
                address:[
                    { required: true, message: '请选择门店地址', trigger: 'blur' }
                ],
                localtion:[
                    { required: true, message: '请标注门店位置', trigger: 'blur' }
                ],
                isopen:[
                    { required: true, message: '请选择店铺状态', trigger: 'blur' }
                ]
            },
        }
    },
    created(){
        this.id = this.$route.params.id
        // this.getCityList()
        this.editStore()
        this.getStatusList()
    },
    mounted(){
    },
    methods:{
        // getCityList(){
        //     const transformedData = this.cities.map(city => {
        //         const cityObject = {
        //             value: city.name,
        //             label: city.name,
        //             children: city.districts ? city.districts.map(district => ({
        //                 value: district,
        //                 label: district
        //             })) : []
        //         };
        //         return cityObject;
                
        //     });
        //     this.citylist = transformedData
        // },
        async getStatusList(){
            const { data: res } = await this.$http.get('status/')
            this.statuslist = res.data
        },
        async editStore(){
            const { data: res } = await this.$http.get('storeinfo/' + this.id )
            this.editForm = res
            this.editForm.admin_token = window.sessionStorage.getItem('admin_token') 

            // const cityarr = []
            // cityarr.push(res.city)
            // cityarr.push(res.county)
            // this.editForm.city = cityarr
            // this.fileList = [{name:res.storepic,url:this.imgURL+'static/pics/'+res.storepic}]
            // this.addressChange(15)
        },
        // change(data) {
        //     this.editForm.goods_info = data;
        // },
        // editPic(file, fileList){
        //     this.editForm.storepic = file.picname
        // },


        async EditStoreSave(){
            // this.editForm.city = this.editForm.city.join(', ')
            console.log(this.editForm);
              this.$refs.editFormRef.validate( async valid=>{
                if (!valid) return
                const { data:res } = await this.$http.post('storeedit/'+this.id,this.editForm)
                if(res.status===0)
                {
                    this.$message.success('修改门店成功！')
                    this.$router.push({path: '/stores'})
                }
              })
       },
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
        
    }
    .el-breadcrumb{
        width: 100%;
    }
    
</style>