<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员卡管理</el-breadcrumb-item>
            <el-breadcrumb-item>会员卡列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-input placeholder="请输入会员卡名称" clearable v-model="queryInfo.query" @clear="getList()">
                        <el-button slot="append" icon="el-icon-search" @click="getList()"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="14"><el-button type="primary" @click=" addDialogVisible = true">添加会员卡</el-button></el-col>
            </el-row>
            <!--搜索与添加区域-->
            <!--用户列表区域-->
            <el-table :data="list"  :key="list.index" style="width: 100%" stripe size="middle" height="700">
                <!-- <el-table-column label="ID" type="index"  width="40"></el-table-column> -->
                <el-table-column label="ID" width="50">
                    <template slot-scope="scope">
                        {{ (queryInfo.pagenum - 1) * queryInfo.pagesize + scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column label="会员卡名称" prop="cardname" width="220"></el-table-column>
                <el-table-column label="会员卡类型" prop="cardclass" width="150"></el-table-column>
                <el-table-column label="价格" prop="price" width="150">
                    <template  slot-scope="scope">
                        ￥{{ scope.row.price }}
                    </template>
                </el-table-column>
                <el-table-column label="天数" prop="days" width="100">
                    <template  slot-scope="scope">
                        {{ scope.row.days }} 天
                    </template>
                </el-table-column>
                <el-table-column label="描述" prop="carddescribe" width="200"></el-table-column>
                <el-table-column label="是否通卡" prop="istong" width="100">
                    <template  slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.istong == '0'">否</el-tag>
                        <el-tag type="danger" v-else>是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="抖音商品ID" prop="douyinid" width="200"></el-table-column>
                <el-table-column label="美团商品ID" prop="meituanid" width="200"></el-table-column>
                <el-table-column label="备注" prop="remark"></el-table-column>
                <el-table-column label="操作">
                    <!--作用域插槽-->
                    <template slot-scope="scope"> 
                        <el-button type="primary" icon="el-icon-edit" size="mini" @click="EditInfo(scope.row.id)"></el-button>
                        <!-- <el-button type="danger" icon="el-icon-delete" size="mini" @click="DelInfo(scope.row.id)"></el-button> -->

                </template>
                </el-table-column>
            </el-table>
            <!--用户列表区域-->
            <!--分页区域-->
            <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--分页区域-->
            <!--添加用户对话框-->
            <el-dialog title="添加会员卡" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
                <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
                    <el-form-item label="会员卡名称" prop="cardname">
                        <el-input v-model="addForm.cardname"></el-input>
                    </el-form-item>
                    <el-form-item label="会员卡描述" prop="carddescribe">
                        <el-input v-model="addForm.carddescribe"></el-input>
                    </el-form-item>
                    <el-form-item label="会员卡类型" prop="cardclassid">
                        <el-select  v-model="addForm.cardclassid"  placeholder="请选择类型" style="width:300px">
                            <el-option v-for="item in classlist" :key="item.id" :label="item.classname" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="会员卡价格" prop="price">
                        <el-input v-model="addForm.price" style="width:300px"></el-input> 元
                    </el-form-item>
                    <el-form-item label="会员卡天数" prop="days">
                        <el-input v-model="addForm.days" style="width:300px"></el-input> 天
                    </el-form-item>
                    <el-form-item label="会员卡备注" prop="remark">
                        <el-input v-model="addForm.remark"></el-input>
                    </el-form-item>
                    <el-form-item label="是否是通卡" prop="istong">
                        <el-switch active-value="1" inactive-value="0" v-model="addForm.istong"></el-switch>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="AddInfo">确 定</el-button>
                </span>
            </el-dialog>
            <!--添加用户对话框-->
            <!--编辑用户对话框-->
            <el-dialog title="编辑会员卡" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
                <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
                    <el-form-item label="会员卡名称" prop="cardname">
                        <el-input v-model="editForm.cardname"></el-input>
                    </el-form-item>
                    <el-form-item label="会员卡描述" prop="carddescribe">
                        <el-input v-model="editForm.carddescribe"></el-input>
                    </el-form-item>
                    <el-form-item label="会员卡类型" prop="cardclassid">
                        <el-select  v-model="editForm.cardclassid"  placeholder="请选择类型" style="width:300px">
                            <el-option v-for="item in classlist" :key="item.id" :label="item.classname" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="会员卡价格" prop="price">
                        <el-input v-model="editForm.price" style="width:300px"></el-input> 元
                    </el-form-item>
                    <el-form-item label="会员卡天数" prop="days">
                        <el-input v-model="editForm.days" style="width:300px"></el-input> 天
                    </el-form-item>
                    <el-form-item label="会员卡备注" prop="remark">
                        <el-input v-model="editForm.remark"></el-input>
                    </el-form-item>
                    <el-form-item label="抖音团购ID" prop="douyinid">
                        <el-input v-model="editForm.douyinid"></el-input>
                    </el-form-item>
                    <el-form-item label="美团团购ID" prop="meituanid">
                        <el-input v-model="editForm.meituanid"></el-input>
                    </el-form-item>
                    <el-form-item label="是否是通卡" prop="istong">
                        <el-switch active-value="1" inactive-value="0" v-model="editForm.istong"></el-switch>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="editDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="EditInfoSave(editForm.id)">确 定</el-button>
                </span>
            </el-dialog>
            <!--编辑用户对话框-->
        </el-card>
    </div>
</template>
<script>
export default {
    data() {

        return{
            
            queryInfo: {
                query: '',
                pagenum: 1,
                pagesize: 10
            },
            total:0,
            list: [],
            addDialogVisible:false,
            editDialogVisible:false,
            addForm:{
                cardname:'',
                carddescribe:'',
                cardclassid:'',
                price:'',
                days:'',
                remark:'',
                istong:''
            },
             //编辑表单数据
             editForm:{
                id:'',
                cardname:'',
                cardclassid:'',
                carddescribe:'',
                price:'',
                days:'',
                remark:'',
                istong:''
            },
            //添加表单验证规则
            addFormRules:{
                cardname:[
                    { required: true, message: '请输入会员卡名称', trigger: 'blur' }
                ],
                cardclassid:[
                    { required: true, message: '请选择会员卡类型', trigger: 'blur' }
                ],
                price:[
                    { required: true, message: '请输入会员卡价格', trigger: 'blur' }
                ],
                days:[
                    { required: true, message: '请输入天数', trigger: 'blur' }
                ]
            },
            editFormRules:{
                cardname:[
                    { required: true, message: '请输入会员卡名称', trigger: 'blur' }
                ],
                cardclassid:[
                    { required: true, message: '请选择会员卡类型', trigger: 'blur' }
                ],
                price:[
                    { required: true, message: '请输入会员卡价格', trigger: 'blur' }
                ]
            },
            classlist:[],
        }
        
    },
    created() {
        this.getClass()
        this.getList()
    },
    mounted(){
        this.addForm.admin_token = window.sessionStorage.getItem('admin_token') 
    },
    methods:{
        async getClass(){
            const { data: res } = await this.$http.get('cardclass/')
            this.classlist = res.data
        },
        async getList() {
           const { data: res} = await this.$http.get('cardlist/',{ params: this.queryInfo })
           if(res.status == 1){
                this.total = res.data.totalCount;
                this.list = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                this.list[index].id= res.data.list[index].pk;
            }
            console.log(this.list)
        },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getList()
       },
       addDialogClosed(){
            this.$refs.addFormRef.resetFields()
       },
       
       async AddInfo(){
              this.$refs.addFormRef.validate( async valid=>{
                if (!valid) return
                const { data:res } = await this.$http.post('cardadd/',this.addForm)
                if(res.status===0)
                {
                    this.$message.success('添加成功！')
                    this.addDialogVisible = false
                    this.getList()
                }
              })
       },
       async EditInfo(id){
           const { data: res } = await this.$http.get('cardinfo/'+id)
            this.editForm = res
            this.editForm.admin_token = window.sessionStorage.getItem('admin_token') 
            this.editDialogVisible = true
       },
       EditInfoSave(id){
           this.$refs.editFormRef.validate(async valid => {
                if (!valid) return
                const { data: res } = await this.$http.post('cardedit/' + id,this.editForm)
                if (res.status === 0) {
                    this.$message.success('修改成功')
                    this.editDialogVisible = false
                    this.getList()
                }
            })
       },
       editDialogClosed(){
            this.$refs.editFormRef.resetFields()
       },
       async DelInfo(id){
                const confirmResult = await this.$confirm('是否要删除此信息吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).catch(err => err)

                    if (confirmResult !== 'confirm'){
                        return this.$message.info('已取消删除！')
                    }
                    const { data: res } = await this.$http.get('carddel/'+ id,{ params:{admin_token:this.addForm.admin_token} })
                
                    if (res.status === 0) { 
                        this.$message({message: '删除成功！',type: 'success'})
                    }
                    this.getList()
                    
       },
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    el-table__row>td {
    border: none;
    }

    .el-table::before {
    height: 0px;
    
    }
</style>