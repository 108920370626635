<template>
  <div>
      <!--面包屑导航区域-->
      <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>订单管理</el-breadcrumb-item>
          <el-breadcrumb-item>订单列表</el-breadcrumb-item>
      </el-breadcrumb>
      <!--面包屑导航区域-->
      <!--卡片视图区-->
      <el-card>
        <!-- {{ orderlist }} -->
        <!--搜索与添加区域-->
        <el-row :gutter="20">
              <el-col :span="24">
                <el-input placeholder="请输入订单编号/用户名/卡名" clearable v-model="query" @clear="getOrderList()" style="width: 350px;padding-right: 10px;">
                      <el-button slot="append" icon="el-icon-search" @click="getOrderList()"></el-button>
                </el-input>
                <el-select v-model="storename"  placeholder="请选择门店" @change="getOrderList1()"  style="margin-right:10px;width: 300px;" clearable  filterable>
                      <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.storename"></el-option>
                </el-select>
                <el-date-picker
                      v-model="customdate"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickerOptions" @change="CustomeDate" style="margin: 0 10px">
                  </el-date-picker>
                  <el-select  v-model="type" clearable  placeholder="请选择交易类型" @change="typeOrderList()"  style="width:150px">
                          <el-option label="平台" value="0"></el-option>
                          <el-option label="抖音" value="1"></el-option>
                          <el-option label="美团" value="2"></el-option>
                          <el-option label="赠送" value="3"></el-option>
                          <el-option label="分享" value="4"></el-option>
                  </el-select>
                  <el-select  v-model="status" clearable  placeholder="是否失效" @change="statusOrderList()"  style="margin: 0 10px;;width:150px">
                      <el-option label="有效" value="0"></el-option>
                      <el-option label="失效" value="1"></el-option>
                  </el-select>
                
              </el-col>
        </el-row>
        <br>
          <!--用户列表区域-->
          <el-table :data="orderlist" style="width: 100%" ref="invoiceTable" stripe size="small" height="600" :summary-method="getSummaries"
          show-summary v-loading="isLoading">
              <el-table-column label="ID" type="index"  width="50" fixed></el-table-column>
              <el-table-column label="订单编号" prop="orderno" width="140"></el-table-column>
              <el-table-column label="用户名" prop="username" width="100"></el-table-column>
              <el-table-column label="店铺名" prop="storename" width="150" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="卡名" prop="cardname" width="260" :show-overflow-tooltip="true">
                  <template slot-scope="scope">
                      <div style="display: flex;">
                          <div style="padding-right: 5px;line-height: 30px;">
                              {{ scope.row.cardinfo.cardname }}
                          </div>
                      </div>
                  </template>
              </el-table-column>
              <el-table-column label="金额" prop="amount" width="180">
                  <template slot-scope="scope" v-if="scope.row.cardinfo.amount">
                      <span style="color: #ff0000;">￥{{scope.row.cardinfo.amount}}</span>
                  </template>
              </el-table-column>
              <el-table-column label="来源" prop="cardfrom" width="230">
                  <template slot-scope="scope">
                      <el-tag type="success" v-if="scope.row.cardfrom == '0'">平台</el-tag>
                      <el-tag type="danger" v-if="scope.row.cardfrom == '1'">抖音</el-tag>
                      <el-tag type="warning" v-if="scope.row.cardfrom == '2'">美团</el-tag>
                      <el-tag type="primary" v-if="scope.row.cardfrom == '3'">赠送</el-tag>
                      <el-tag type="info" v-if="scope.row.cardfrom == '4'">分享</el-tag>
                      <span v-if="scope.row.cardfrom == '1'" style="padding-left: 10px;">劵码：{{ scope.row.extra }}</span>
                  </template>
              </el-table-column>
              <el-table-column label="收款商户" prop="mchid" width="100"></el-table-column>
              <el-table-column label="状态" prop="status"  width="200">
                  <template slot-scope="scope">
                      <el-tag type="success" v-if="scope.row.status == '0'">有效</el-tag>
                      <el-tag type="info" v-if="scope.row.status == '1'">失效</el-tag>
                  </template>
              </el-table-column>
              <!-- <el-table-column label="开始时间" prop="startdate" width="150"></el-table-column> -->
              <!-- <el-table-column label="截止时间" prop="enddate" width="150"></el-table-column> -->
              <el-table-column label="订单状态" prop="status" >
                  <template slot-scope="scope">
                      <el-link type="success">已支付</el-link>
                  </template>
              </el-table-column>
              <el-table-column label="订单日期" prop="date" width="150" fixed="right"></el-table-column>
          </el-table>
          <!--用户列表区域-->
          <div align="center"  style="padding: 20px;font-size: 14px;color: #999;">
              共 {{count}} 条数据
          </div>
      </el-card>
      <div style="padding-top: 20px;text-align: right;">
          <el-input placeholder="请输入生成条数" v-model="count" style="width: 200px;padding-right: 10px;">
            <el-button slot="append" @click="SetCount">生成</el-button>
          </el-input>
          <el-button type="success" icon="el-icon-refresh" @click="RefreshData">刷新数据</el-button>
      </div>
  </div>
</template>

<script>
import Mock from "mockjs";

export default {
  data() {
    return {
      pickerOptions: {
          shortcuts: [{
              text: '最近一周',
              onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
              }
          }, {
              text: '最近一个月',
              onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
              }
          }, {
              text: '最近三个月',
              onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
              }
          }]
      },
      query:'',
      storename:'',
      customdate:'',
      startdate:'',
      enddate:'',
      type:'',
      status:'',
      count:100,
      isLoading:false,
      oldorderlist:[],
      orderlist: [], // 存储生成的订单数据
      storelist: [], // 存储店铺名称列表
      cardList: [
        { cardname: "日卡", amount: 19, days: 1, weight: 10 },
        { cardname: "月卡30D（单店使用）", amount: 99, days: 30, weight: 20 },
        { cardname: "21天卡（全国通用）", amount: 169, days: 21, weight: 60 },
        { cardname: "季卡90D（单店使用）", amount: 399, days: 90, weight: 3 },
        { cardname: "半年卡180D（单店使用）", amount: 599, days: 180, weight: 3 },
        { cardname: "年卡365D（全国通用）", amount: 999, days: 365, weight: 4 }
      ] // 卡和金额列表，新增权重字段
    };
  },
  created() {
    this.extendMockRandom(); // 扩展 Mock 随机函数
    this.GetStore(); // 获取店铺列表
  },
  methods: {
    // 扩展 Mock.js 的随机函数
    // extendMockRandom() {
    //   const vm = this;
    //   Mock.Random.extend({
    //     orderNo() {
    //       const timestamp = new Date().getTime(); // 当前时间戳
    //       const randomNum = Math.floor(Math.random() * 1000); // 随机数
    //       return `${timestamp}${randomNum.toString().padStart(3, "0")}`;
    //     },
    //     phone() {
    //       const prefix = ["139", "138", "137", "136", "135"];
    //       const randomPrefix = prefix[Math.floor(Math.random() * prefix.length)];
    //       const randomSuffix = Math.random().toString().slice(2, 10);
    //       return `${randomPrefix}${randomSuffix}`;
    //     },
    //     storeName() {
    //       if (!vm.storelist || vm.storelist.length === 0) {
    //         console.warn("店铺列表为空，返回默认值 '未知店铺'");
    //         return "未知店铺";
    //       }
    //       const randomIndex = Math.floor(Math.random() * vm.storelist.length);
    //       return vm.storelist[randomIndex].storename;
    //     },
    //     cardInfo() {
    //       const weightedCards = vm.cardList.flatMap(card =>
    //         Array(card.weight).fill(card)
    //       );
    //       const randomIndex = Math.floor(Math.random() * weightedCards.length);
    //       return weightedCards[randomIndex];
    //     }
    //   });
    // },
    // 扩展 Mock.js 的随机函数
    extendMockRandom() {
      const vm = this;
      Mock.Random.extend({
        orderNo() {
          const timestamp = new Date().getTime(); // 当前时间戳
          const randomNum = Math.floor(Math.random() * 1000); // 随机数
          return `${timestamp}${randomNum.toString().padStart(3, "0")}`;
        },
        phone() {
          const prefix = ["139", "138", "137", "136", "135"];
          const randomPrefix = prefix[Math.floor(Math.random() * prefix.length)];
          const randomSuffix = Math.random().toString().slice(2, 10);
          return `${randomPrefix}${randomSuffix}`;
        },
        storeName() {
          if (!vm.storelist || vm.storelist.length === 0) {
            console.warn("店铺列表为空，返回默认值 '未知店铺'");
            return "未知店铺";
          }
          const randomIndex = Math.floor(Math.random() * vm.storelist.length);
          return vm.storelist[randomIndex].storename;
        },
        cardInfo() {
          // 让日卡占60%的比重
          const weightedCards = [
            ...Array(60).fill(vm.cardList[0]), // 日卡占60%
            ...Array(20).fill(vm.cardList[1]), // 月卡占20%
            ...Array(10).fill(vm.cardList[2]), // 21天卡占10%
            ...Array(5).fill(vm.cardList[3]), // 季卡占5%
            ...Array(3).fill(vm.cardList[4]), // 半年卡占3%
            ...Array(2).fill(vm.cardList[5])  // 年卡占2%
          ];

          const randomIndex = Math.floor(Math.random() * weightedCards.length);
          return weightedCards[randomIndex];
        }
      });
    },
    // 生成最近20天的随机日期
    randomRecentDate() {
      const now = new Date();
      const startOfRange = new Date();
      startOfRange.setDate(now.getDate() - 20); // 最近20天的开始日期
      const randomDate = new Date(
          startOfRange.getTime() +
          Math.random() * (now.getTime() - startOfRange.getTime())
      );
      return randomDate.toISOString().slice(0, 19).replace("T", " "); // 格式化为 "YYYY-MM-DD HH:mm:ss"
    },
    // 根据开始日期和卡片天数计算结束日期
    calculateEndDate(startDate, days) {
      const start = new Date(startDate);
      start.setDate(start.getDate() + days);
      return start.toISOString().slice(0, 19).replace("T", " ");
    },
    // 使用 Mock.js 生成数据
    async generateMockData() {
      this.orderlist = Mock.mock({
          [`data|${this.count}`]: [
              {
                  "id|+1": 1, // 自增ID
                  orderno: "@orderNo", // 调用自定义订单号生成器
                  username: "@phone", // 随机手机号
                  storename: "@storeName", // 随机店名
                  cardinfo: "@cardInfo", // 随机卡信息
                  status: "0", // 自定义 status 生成规则
                  cardfrom: "@pick(['0', '1'])", // 随机来源
                  extra: "@orderNo", // 额外信息
              },
          ],
      }).data.map((item) => {
          const storeName = this.storename || item.storename;

          // 生成随机 startdate 和 enddate
          const startDate = this.randomRecentDate(); // 生成随机开始日期
          const cardInfo = item.cardinfo;
          const endDate = this.calculateEndDate(startDate, cardInfo?.days || 0); // 根据卡片天数计算结束日期

          // 确定 date 的取值范围
          let date;
          if (this.customdate && this.customdate.length === 2) {
              const customStartDate = new Date(this.customdate[0]);
              const customEndDate = new Date(this.customdate[1]);
              if (!isNaN(customStartDate) && !isNaN(customEndDate)) {
                  let randomTime;
                  if (customStartDate.toDateString() === customEndDate.toDateString()) {
                      // 如果是同一天，随机生成一天中的不同时间
                      const startOfDay = new Date(customStartDate).setHours(0, 0, 0, 0);
                      const endOfDay = new Date(customStartDate).setHours(23, 59, 59, 999);
                      randomTime = Math.random() * (endOfDay - startOfDay) + startOfDay;
                  } else {
                      // 不同天，按照原有逻辑随机生成时间
                      randomTime =
                          Math.random() * (customEndDate.getTime() - customStartDate.getTime()) +
                          customStartDate.getTime();
                  }
                  date = new Date(randomTime); // 确保是 Date 对象
              } else {
                  console.error("Invalid customdate range:", this.customdate);
                  date = new Date(startDate); // 回退到默认值，确保是 Date 对象
              }
          } else {
              date = new Date(startDate); // 如果 customdate 不存在或无效，保持原来的 startDate
          }

          return {
              ...item,
              storename: storeName, // 动态设置店名
              startdate: this.formatDateTime(new Date(startDate)), // 确保 startDate 是 Date 对象
              enddate: this.formatDateTime(new Date(endDate)), // 确保 endDate 是 Date 对象
              date: this.formatDateTime(date), // 确保 date 是 Date 对象
          };
      });

    // 确保 customdate[1] 日期的数据至少有 8 条
    if (this.customdate && this.customdate.length === 2) {
    const customTargetDate = new Date(this.customdate[1]);
    const formattedTargetDate = this.formatDateTime(customTargetDate).split(" ")[0];

    // 统计目标日期的数据条目
    const targetDateItems = this.orderlist.filter(
        (item) => item.date.split(" ")[0] === formattedTargetDate
    );

    // 计算缺少的条目数量
    const missingCount = 8 - targetDateItems.length;
    if (missingCount > 0) {
        const usedTimes = new Set(
            targetDateItems.map((item) => item.date.split(" ")[1])
        );

        const additionalItems = Mock.mock({
            [`data|${missingCount}`]: [
                {
                    "id|+1": this.orderlist.length + 1, // 自增ID从现有长度继续
                    orderno: "@orderNo",
                    username: "@phone",
                    storename: "@storeName",
                    cardinfo: "@cardInfo",
                    status: "0",
                    cardfrom: "@pick(['0', '1'])",
                    extra: "@orderNo",
                },
            ],
        }).data.map((item) => {
            // 动态生成不重复的时间
            let randomTime;
            do {
                const randomHours = Math.floor(Math.random() * 24); // 0-23 小时
                const randomMinutes = Math.floor(Math.random() * 60); // 0-59 分钟
                const randomSeconds = Math.floor(Math.random() * 60); // 0-59 秒
                randomTime = `${String(randomHours).padStart(2, "0")}:${String(
                    randomMinutes
                ).padStart(2, "0")}:${String(randomSeconds).padStart(2, "0")}`;
            } while (usedTimes.has(randomTime)); // 确保时间不重复
            usedTimes.add(randomTime);

            // 生成完整的日期时间
            const randomDateTime = `${formattedTargetDate} ${randomTime}`;

            return {
                ...item,
                startdate: this.formatDateTime(customTargetDate),
                enddate: this.formatDateTime(
                    this.calculateEndDate(customTargetDate, 30)
                ),
                date: randomDateTime, // 动态时间
            };
        });

        // 合并额外数据到 orderlist
        this.orderlist = this.orderlist.concat(additionalItems);
    }
}

    // 按 date 降序排列
    this.orderlist.sort((a, b) => new Date(b.date) - new Date(a.date));
    this.oldorderlist = this.orderlist;
    console.log("生成的订单数据（最近20天，每条不同）：", this.orderlist);
},
formatDateTime(date) {
    if (!(date instanceof Date)) {
        console.error("Invalid date passed to formatDateTime:", date);
        return "Invalid Date";
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
},

    async GetStore() {
      try {
        const { data: res } = await this.$http.get("store/");
        if (res.status === 0) {
          this.storelist = res.data;
          this.storeMap = res.data.reduce((acc, store) => {
            acc[store.storeid] = store.storename;
            return acc;
          }, {});
          console.log("获取的店铺列表：", this.storelist);
          this.generateMockData(); // 成功获取店铺列表后生成 Mock 数据
        } else {
          console.error("获取店铺数据失败：", res.message);
        }
      } catch (error) {
        console.error("获取店铺接口出错：", error);
      }
    },
    getSummaries(param) {
      this.$nextTick(() => {
          this.$refs.invoiceTable.doLayout(); // invoiceTable替换为你的el-table设置的ref值
      });
      // console.log(param);
      const { columns, data } = param;
      const sums = [];
      
      columns.forEach((column, index) => {
          // 如果是第一列，显示 "总价"
          if (index === 0) {
              sums[index] = '合计';
              return;
          }
          // 只计算 `amount` 列的合计
          if (column.property === 'amount') {
              const values = data.map(item => {
                  // 如果 amount 嵌套在 cardinfo 中，调整这里的取值
                  const value = item.cardinfo ? Number(item.cardinfo.amount) : 0;
                  return value;
              });
              let total = values.reduce((prev, curr) => {
                  if (!isNaN(curr)) {
                      return prev + curr; // 累加合计值
                  }
                  return prev;
              }, 0);
              sums[index] = `${total.toFixed(1)} 元`; // 保留一位小数后加单位
          } else {
              sums[index] = ''; // 非 `amount` 列不显示合计
          }
      });

      return sums;
    },
    getOrderList(){
      if (this.query){
        const arr = this.filterData(this.orderlist,this.query)
        this.orderlist = arr
      }else{
        this.orderlist = this.oldorderlist
      }
      this.count  = this.orderlist.length
    },
    getOrderList1(){
      if (this.storename){
        const arr = this.oldorderlist.filter(item =>{
         return item.storename.includes(this.storename)
        })
        this.orderlist = arr
      }else{
        this.orderlist = this.oldorderlist
      }
      this.count  = this.orderlist.length
    },
    // 模糊查询函数
    filterData(data, searchTerm) {
      return data.filter(item => {
        // 检查 orderno、username 和 cardname 中是否包含 searchTerm
        return item.orderno.includes(searchTerm) || 
              item.username.includes(searchTerm) || 
              item.cardinfo.cardname.includes(searchTerm);
      });
    },
    CustomeDate(){
          if(this.customdate == null){
              this.startdate = ''
              this.enddate = ''
              this.orderlist = this.oldorderlist
          }else{
              this.startdate = new Date(this.customdate[0]);  // 将 startdate 转换为 Date 对象
              this.enddate = new Date(this.customdate[1]);    // 将 enddate 转换为 Date 对象
              const arr = this.oldorderlist.filter(item =>{
                const orderStartDate = new Date(item.startdate);
                return orderStartDate >= this.startdate && orderStartDate <= this.enddate;
              })
              this.orderlist = arr
          }
          this.count  = this.orderlist.length
    },
    typeOrderList(){
      if (this.type){
        const arr = this.oldorderlist.filter(item =>{
         return item.cardfrom.includes(this.type)
        })
        this.orderlist = arr
      }else{
        this.orderlist = this.oldorderlist
      }
      this.count  = this.orderlist.length
    },
    statusOrderList(){
      if (this.status){
        const arr = this.oldorderlist.filter(item =>{
         return item.status.includes(this.status)
        })
        this.orderlist = arr
      }else{
        this.orderlist = this.oldorderlist
      }
      this.count  = this.orderlist.length
    },
    SetCount() {
      // 开始加载状态
      this.isLoading = true;

      // 使用 setTimeout 模拟延迟
      setTimeout(() => {
        this.generateMockData(); // 生成数据
        this.isLoading = false;   // 完成数据生成后关闭加载动画
      }, 100);  // 模拟小延时，确保 isLoading 被更新
    },
    RefreshData(){
      this.generateMockData()
    }
  }
};
</script>


<style scoped>
pre {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
}
.cardcss{
    margin: 5px auto ;
    width: 100%;
}
.el-breadcrumb{
    width: 100%;
}
</style>
