<template>
    <el-container class="home-container">
        <el-aside width="260px">
            <el-menu default-active="2" background-color="#020e24" text-color="#ecedee" active-text-color="#c7401d" unique-opened router  style="height: 100%;">
                <el-menu-item>
                    <div style="padding: 10px;color:#c7401d;font-weight: bold;font-size: 20px;">
                        <img src="@/assets/image/storelogo.png" style="height: 30px;vertical-align: middle;border-radius: 10px;"></img>
                        {{name_token}}
                    </div>
                </el-menu-item>
                <el-menu-item>
                    <div style="color:#c7401d;font-weight: bold;padding-left:20px">共享健身后台管理系统</div>
                </el-menu-item>
                <el-submenu index="0">
                    <template slot="title">
                    <i class="el-icon-s-data"></i>
                    <span>财务统计</span>
                    </template>
                    <el-menu-item index="/joinwelcome" @click="saveNavState('/joinwelcome')"><i class="el-icon-menu"></i>财务统计数据</el-menu-item>
                </el-submenu>
                <el-submenu index="1">
                    <template slot="title">
                    <i class="el-icon-postcard"></i>
                    <span>会员卡管理</span>
                    </template>
                    <el-menu-item index="/storecard"  @click="saveNavState('/storecard')"><i class="el-icon-menu"></i>会员卡设置</el-menu-item>
                </el-submenu>
                <el-submenu index="2">
                    <template slot="title">
                    <i class="el-icon-user"></i>
                    <span>会员管理</span>
                    </template>
                    <el-menu-item index="/storeusers" @click="saveNavState('/storeusers')"><i class="el-icon-menu"></i>会员列表</el-menu-item>
                </el-submenu>
                <el-submenu index="3">
                    <template slot="title">
                    <i class="el-icon-s-order"></i>
                    <span>订单管理</span>
                    </template>
                    <el-menu-item index="/storeorder"  @click="saveNavState('/storeorder')"><i class="el-icon-menu"></i>订单列表</el-menu-item>
                </el-submenu>
                <!-- <el-submenu index="4">
                    <template slot="title">
                    <i class="el-icon-s-order"></i>
                    <span>拓客管理</span>
                    </template>
                    <el-menu-item index="/storediffer"  @click="saveNavState('/storediffer')"><i class="el-icon-menu"></i>异业合作</el-menu-item>
                </el-submenu> -->
            </el-menu>
        </el-aside>
        <el-container>
            <el-header>
                <div style="padding-left: 20px;color:#c7401d">
                    <!-- <i class="el-icon-message-solid"></i><span style="padding-left: 10px;">下面发布一则通知.....</span> -->
                    <!-- <el-button type="primary" icon="el-icon-search">搜索</el-button>
                    <i class="el-icon-search" color="#ff0000"></i>
                    <span style="font-size:25px;color:#c7401d;padding-left:20px">共享健身后台管理系统--{{name_token}}</span> -->
                </div>
                <div>
                    
                    <el-button @click="editDialogVisible = true">修改密码</el-button>
                    <el-button type="" @click="LogOut">退出</el-button>
                </div>
            </el-header>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
        <!--修改密码对话框-->
        <el-dialog title="修改密码" :visible.sync="editDialogVisible" width="40%" @close="editDialogClosed">
            <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
                <el-form-item label="原密码" prop="password">
                    <el-input v-model="editForm.password" show-password></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="new_password">
                    <el-input v-model="editForm.new_password" show-password></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="con_password">
                    <el-input v-model="editForm.con_password" show-password></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editPass">确 定</el-button>
            </span>
        </el-dialog>
        <!--修改密码对话框-->
    </el-container>
</template>
<script>
export default {
    data(){
        //验证原密码规则
        // var oldPass = (rule, value, callback) => {
        //     if (value !== this.old_password) {
        //     callback(new Error('原密码不正确!'));
        //     } else {
        //     callback();
        //     }
        // }
        //验证确认密码规则
        var validatePass = (rule, value, callback) => {
            if (value !== this.editForm.new_password) {
            callback(new Error('两次输入密码不一致!'));
            } else {
            callback();
            }
        }
        return {
            isCollapse: false,
            activePath: '',
            editDialogVisible:false,
            old_password:'',
            editForm:{
                 password:'',
                 new_password:'',
                 con_password:''
            },
            editFormRules: {
                password: [
                    { required: true, message: '请输入原密码', trigger: 'blur' },
                    // { validator: oldPass, trigger: 'blur'}
                ],
                new_password: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
                ],
                con_password:[
                    { required: true, message: '请输入确认密码', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur'}
                ],
            }
        }
    },
    created() {
        this.activePath = window.sessionStorage.getItem('activePath')
        this.admin_token = window.sessionStorage.getItem('join_token')
        this.name_token = window.sessionStorage.getItem('joinname_token')
        // this.role_token = window.sessionStorage.getItem('role_token')
        this.storeid_token = window.sessionStorage.getItem('joinstoreid_token')
        
        this.old_password = window.sessionStorage.getItem('joinpass_token')
	},
    methods:{
        saveNavState(activePath) {
			window.sessionStorage.setItem('activePath', activePath)
			this.activePath = activePath
			
        },
        LogOut(){
            window.sessionStorage.clear();
            this.$router.push('/store');
        },
        editDialogClosed(){
           this.$refs.editFormRef.resetFields()
       },
       editPass(){
           this.$refs.editFormRef.validate( async valid => {
                if(!valid) return;
                console.log(this.editForm);
                const { data: res } = await this.$http.post('joineditpass/',{username:this.admin_token,oldpwd:this.editForm.password,password:this.editForm.new_password})
                if (res.status === 0) {
                    this.$message.success('密码修改成功！')
                    this.editDialogVisible = false
                }
                if (res.status === 1) {
                    this.$message.error('原密码不正确！')
                }
            })
       },
       openNewWindow(url) {
            window.open(url, '_blank');
       }
    }
}
</script>
<style scoped>
    .home-container{
        padding-top:0px;
        height: 100%;
    }
    .el-header{
        background-color: #ffffff;
        /* background-image: linear-gradient(to right,#6f82e1,#5165f8); */
        display: flex;
        justify-content: space-between;
        padding-left: 100px;
        align-items: center;
        font-size: 20px;
        color: #fff;
        height: 60px !important;
    }
    .el-aside{
	    background-color: #fff;
    }
    .el-main {
        background-color:#FAFAFA;
    }
    .el-menu {
		border-right: none;
    }
    /* .el-submenu :hover{
        background-color: lightblue !important;
    } */
</style>