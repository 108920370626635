<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>加盟商管理</el-breadcrumb-item>
            <el-breadcrumb-item>加盟商列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-input placeholder="请输入加盟商姓名/手机号" clearable v-model="queryInfo.query" @clear="getList()">
                        <el-button slot="append" icon="el-icon-search" @click="getList()"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="6">
                    <el-select  v-model="queryInfo.query1" clearable  placeholder="请选择状态" @change="getList()"  style="margin-right:10px">
                            <el-option label="正常" value="0"></el-option>
                            <el-option label="禁用" value="1"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="12"><el-button type="primary" @click="addDialogVisible = true">添加加盟商</el-button></el-col>
            </el-row>
            <!--搜索与添加区域-->
            <!--商品列表区域-->
            <el-table :data="list" style="width: 100%" stripe size="middle">
                <el-table-column label="ID" type="index" ></el-table-column>
                <el-table-column label="加盟商姓名" prop="name" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="加盟商手机" prop="telephone"></el-table-column>
                <el-table-column label="商家用户名" prop="username"></el-table-column>
                <!-- <el-table-column label="商家密码" prop="password"></el-table-column> -->
                <el-table-column label="关联门店" prop="storename" width="300">
                    <template slot-scope="scope">
                       <el-tag v-if="scope.row.isopen=='1'"  type="success">营业</el-tag>
                       <el-tag v-else-if="scope.row.isopen=='2'" type="info">关闭</el-tag>
                       <el-tag v-else-if="scope.row.isopen=='3'" type="warning">装修中</el-tag>
                       {{ scope.row.storename }}
                    </template>
                </el-table-column>
                <!-- <el-table-column label="关联用户" prop="zhanghao"></el-table-column> -->
                <el-table-column label="状态" prop="status">
                    <template slot-scope="scope">
                       <el-tag v-if="scope.row.status=='0'"  type="success">正常</el-tag>
                       <el-tag v-if="scope.row.status=='1'" type="info">禁用</el-tag>
                       <!-- <el-tag v-else-if="scope.row.isopen=='3'" type="warning">装修中</el-tag> -->
                    </template>
                </el-table-column>
                <el-table-column label="加盟时间" prop="joindate"></el-table-column>
                <el-table-column label="操作">
                    <!--作用域插槽-->
                    <template slot-scope="scope">
                        <!-- {{scope.row}} -->
                        <el-button type="success" icon="el-icon-edit" size="mini" @click="EditMerchant(scope.row.id)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini" @click="DelMerchant(scope.row.id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--商品列表区域-->
             <!--分页区域-->
             <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--分页区域-->
            <!--添加对话框-->
            <el-dialog title="添加加盟商" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
                <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="addForm.username" placeholder="请输入用户名" ></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input v-model="addForm.password"  type="password"  placeholder="请输入密码" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="addForm.name" placeholder="请输入姓名" ></el-input>
                    </el-form-item>
                    <el-form-item label="手机" prop="telephone">
                        <el-input v-model="addForm.telephone" placeholder="请输入手机" ></el-input>
                    </el-form-item>
                    <el-form-item label="关联门店" prop="storeid">
                        <el-select  v-model="addForm.storeid"  placeholder="请选择门店" style="width:300px">
                            <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.id" :disabled="item.isselect == 1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="加盟时间" prop="joindate">
                        <el-date-picker v-model="addForm.joindate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-switch active-value="1" inactive-value="0" v-model="addForm.status"></el-switch>
                        <span style="padding-left: 10px;" v-if="addForm.status == '0'">正常</span>
                        <span style="padding-left: 10px;" v-if="addForm.status == '1'">禁用</span>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="AddMerchant">确 定</el-button>
                </span>
            </el-dialog>
            <!--添加对话框-->
            <!--编辑对话框-->
            <el-dialog title="编辑加盟商" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
                <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="editForm.username" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password1">
                        <el-input v-model="editForm.password1"  type="password" show-password  placeholder="如果为空则使用原密码"></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="editForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="手机" prop="telephone">
                        <el-input v-model="editForm.telephone"></el-input>
                    </el-form-item>
                    <el-form-item label="关联门店" prop="storeid">
                        <el-select  v-model="editForm.storeid"  placeholder="请选择门店" style="width:300px">
                            <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.id" :disabled="item.isselect == 1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="加盟时间" prop="joindate">
                        <el-date-picker v-model="editForm.joindate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-switch active-value="1" inactive-value="0" v-model="editForm.status"></el-switch>
                        <span style="padding-left: 10px;" v-if="editForm.status == '0'">正常</span>
                        <span style="padding-left: 10px;" v-if="editForm.status == '1'">禁用</span>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="editDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="EditMerchantSave(editForm.id)">确 定</el-button>
                </span>
            </el-dialog>
            <!--编辑对话框-->
        </el-card>
    </div>
</template>
<script>

export default {
    data() {

        return{
            imgURL:this.GLOBAL.imgURL,
            queryInfo: {
                query: '',
                query1:'',
                pagenum: 1,
                pagesize: 10
            },
            total:0,
            list: [],
            storelist:[],
            addDialogVisible:false,
            editDialogVisible:false,
            addForm:{
                 username:'',
                 password:'',
                 name:'',
                 telephone:'',
                 storeid:'',
                 status:'',
                 joindate:''
            },
             //编辑表单数据
             editForm:{
                username:'',
                password1:'',
                name:'',
                telephone:'',
                storeid:'',
                status:'',
                joindate:''
            },
            //添加表单验证规则
            addFormRules:{
                username:[
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                password:[
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
                ],
                name:[
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                telephone:[
                    { required: true, message: '请输入手机号', trigger: 'blur' }
                ],
                storeid:[
                    { required: true, message: '请输入选择关联门店', trigger: 'blur' }
                ]
            },
            editFormRules:{
                name:[
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                telephone:[
                    { required: true, message: '请输入手机号', trigger: 'blur' }
                ],
                storeid:[
                    { required: true, message: '请输入选择关联门店', trigger: 'blur' }
                ]
            },
        }
        
    },
    created() {
         this.getList()
         this.getStoreList()
    },
    mounted(){
        this.addForm.admin_token = window.sessionStorage.getItem('admin_token') 
    },
    
    methods:{
        async getStoreList(){
            const { data: res } = await this.$http.get('store/')
            this.storelist = res.data
        },
        async getList() {
            const { data:res } = await this.$http.get('merchantlist/',{ params: this.queryInfo })
            if(res.status == 1){
                this.total = res.data.totalCount;
                this.list = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                this.list[index].id= res.data.list[index].pk;
            }
        },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getList()
       },
       addDialogClosed(){
            this.$refs.addFormRef.resetFields()
       },
       editDialogClosed(){
            this.$refs.editFormRef.resetFields()
       },
       AddMerchant(){
        this.$refs.addFormRef.validate( async valid=>{
                if (!valid) return
                const { data:res } = await this.$http.post('merchantadd/',this.addForm)
                if(res.status===0)
                {
                    this.$message.success('添加加盟商成功！')
                    this.addDialogVisible = false
                    this.getList()
                    this.getStoreList()
                }
                if(res.status == 1){
                    this.$message.error('此加盟商已存在！')
                }
              })
       },
       async EditMerchant(id){
           const { data: res } = await this.$http.get('merchantinfo/'+id)
            this.editForm = res
            this.editForm.admin_token = window.sessionStorage.getItem('admin_token') 
            this.editDialogVisible = true
       },
       EditMerchantSave(id){
           this.$refs.editFormRef.validate(async valid => {
                if (!valid) return
                const { data: res } = await this.$http.post('merchantedit/' + id,this.editForm)
                if (res.status === 0) {
                    this.$message.success('加盟商修改成功')
                    this.editDialogVisible = false
                    this.getList()
                    this.getStoreList()
                }
            })
       },
       async DelMerchant(id){
                const confirmResult = await this.$confirm('是否要删除此信息吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).catch(err => err)

                    if (confirmResult !== 'confirm'){
                        return this.$message.info('已取消删除！')
                    }
                    const { data: res } = await this.$http.get('/merchantdel/'+ id,{ params:{admin_token:this.addForm.admin_token} })
                
                    if (res.status === 0) { 
                        this.$message({message: '删除成功！',type: 'success'})
                    }
                    this.getList()
                    this.getStoreList()
       },
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    el-table__row>td {
    border: none;
    }

    .el-table::before {
        height: 0px;
    }

</style>